export const PATHS = {
  ROOT: '/',
  NEWS: '/news',
  PRODUCT_DESIGN: '/product-design',
  CO_BRANDING: '/co-branding',
  CONTACT: '/contact',
  MICROPHONES: '/microphones',
  SPEAKERS: '/speakers',
  SONIQUE: '/sonique',
  AUDIOWORX: '/audioworx',
  AUDIOEFX: '/audioefx',
  OVERVIEW: '/overview',
  TEAM: '/team',
  SEARCH: '/search/:query?'
};

export const EXTERNAL_PATHS = {
  LINKEDIN: 'https://linkedin.com/company/harman-embedded-audio',
  YOUTUBE: 'https://www.youtube.com/channel/UCrk41zGATZb3iZDHi6OpCCA',
  COOKIES: 'https://www.harman.com/cookies-statement',
  PRIVACY: 'https://www.harman.com/privacy-policy',
  TERMS_OF_USE: 'https://www.harman.com/terms-use',
  HARMAN: 'https://www.harman.com/',
  CARREERS: 'https://www.harman.com/careers'
}